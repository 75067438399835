@media screen and (max-width: 400px) {
  #features {
    width: 100%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.timeCell {
  background: linear-gradient(to right, #fc9a40 0%, #ff7f00 100%);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10 15 10 15;
  font-weight: 500;
  transition: all 0.7s ease;
}

.timeCell:disabled {
  background: gray
}

.timeCell:hover:not(:disabled) {
  background: linear-gradient(to right, #ffb570 0%, #ffb76f 100%);
  color: rgb(0, 0, 0);
}

.modal-lesson {
  height: fit-content;
  width: 100%;
  max-width: 690px;
  background-color: white;
  border-radius: 30px;
  padding: 40px 80px;
  top: 100px;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s all;
  z-index: 99999;
  margin: auto;
  position: relative;
}

.modal-lesson button {
  height: 50px;
  padding: 0 15 0 15;
  font-weight: 500;
  border: none;
  background-color: #fc9a40;
  color: white;
  border-radius: 40px;
}

.custom-select {
  position: relative;
}

.custom-select select {
  appearance: none;
  width: 100%;
  padding: 16px 30px;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 10px;
  font-size: clamp(14px, 0 * 100vw + 14px, 14px);
  font-weight: 400;
  line-height: 130%;
  color: black;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

.modal-lesson.showed {
  pointer-events: all;
  opacity: 1;
}

input {
  padding: 16px 30px;
  border-radius: 10px;
  border: 1px solid rgb(219, 219, 219);
  font-size: clamp(14px, 0 * 100vw + 14px, 14px);
  font-weight: 400;
  line-height: 130%;
  color: black;
  width: 100%;
}

.input-file {
  position: relative;
  display: inline-block;
}

.input-file span {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  border-radius: 4px;
  background-color: #fc9a40;
  line-height: 22px;
  min-height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
}

.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.input-file input[type=file]:focus+span {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.input-file:hover span {
  background-color: #c97d37;
}

.input-file:active span {
  background-color: #fc9a40;
}

/* Disabled */
.input-file input[type=file]:disabled+span {
  background-color: #eee;
}

.checkbox {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.checkbox:checked {
  background-color: #f8ae0d !important;
  border-color: #f8ae0d !important;
}

.checkbox:checked[type=checkbox] {
  background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==');
}

input::placeholder {
  font-size: clamp(14px, 0 * 100vw + 14px, 14px);
  font-weight: 400;
  line-height: 130%;
  color: rgb(172, 172, 172);
  display: flex;
  align-items: center;
}

.modal__background {
  position: fixed;
  overflow-y: scroll;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  z-index: 10000;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;

}

.modal__background.showed {
  opacity: 1;
  pointer-events: all;
}

.modal__close {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 10;
}

.header__login {
  margin-left: 10px;
}

.header__login-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 14px 35px;
  border: 1px solid #FF7F00;
  background-color: #FF7F00;
  border-radius: 30px;
  transition: 0.3s ease;
  color: white;
  cursor: pointer;
  max-width: 200px;
  max-height: 50px;

  path {
    stroke: white;
    transition: 0.3s ease;
  }
}

.login-btn:hover {
  background-color: white;
  color: #FF7F00 !important;

  path {
    stroke: #FF7F00;
  }
}

.login-btn.white {
  background-color: white;
  color: #FF7F00;

  path {
    stroke: #FF7F00;
  }
}

.login-btn.white:hover {
  background-color: #FF7F00;
  color: white !important;

  path {
    stroke: white;
  }
}

.login-btn.no-bg {
  background-color: transparent;
}

#agreement {
  width: 1.5em;
  height: 1.5em;
  border-radius: 5px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

#agreement:checked {
  background-color: #ff7f00 !important;
  border-color: #ff7f00 !important;
}